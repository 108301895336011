<template>
        <el-dialog title="Categorii" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
            <el-col :md='8'>
                <el-form-item prop="NumeRo" label='Nume Ro' >
                    <el-input  class='full-width' v-model='selectedObject.NumeRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="NumeEn" label='Nume De' >
                    <el-input  class='full-width' v-model='selectedObject.NumeEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugRo" label='Slug Ro' >
                    <el-input  class='full-width' v-model='selectedObject.SlugRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugEn" label='Slug De' >
                    <el-input  class='full-width' v-model='selectedObject.SlugEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Homepage" label='Homepage' >
                    <el-checkbox v-model='selectedObject.Homepage' true-label='1' false-label='0'> Homepage </el-checkbox>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Culoare" label='Culoare' >
                     <el-select class='full-width' v-model='selectedObject.Culoare' ><el-option label='albastru' value='#25408f'></el-option><el-option label='portocaliu' value='rgb(234,137,91)'></el-option><el-option label='rosu' value='#e64946'></el-option><el-option label='verde' value='rgb(116,178,73)'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaTitleRo" label='Meta title ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaTitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaTitleEn" label='Meta title De' >
                    <el-input  class='full-width' v-model='selectedObject.MetaTitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionRo" label='Meta description ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionEn" label='Meta description De' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsRo" label='Meta keywords ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsEn" label='Meta keywords De' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsEn' />
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Categorii_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    SlugRo: '' , SlugEn: '' , NumeRo: '' , NumeEn: '' , Homepage: '' , Culoare: '' , MetaTitleRo: '' , MetaTitleEn: '' , MetaDescriptionRo: '' , MetaDescriptionEn: '' , MetaKeywordsRo: '' , MetaKeywordsEn: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    SlugRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    SlugEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumeRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumeEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Homepage: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Culoare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaTitleRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaTitleEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaDescriptionRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaDescriptionEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaKeywordsRo: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MetaKeywordsEn: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("categorii/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("categorii/get_info_for_dialog" );
                            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("categorii/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        },
        
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>